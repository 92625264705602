<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-0">
                {{ "Đăng nhập" }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-tabs
              v-if="$route.query.admin"
              height="40px"
              v-model="tab"
              show-arrows
            >
              <v-tab>
                Ban điều hành
              </v-tab>
              <v-tab> Giám thị</v-tab>

              <v-tab>
                Cán bộ ra đề
              </v-tab>
              <v-tab>
                Quản trị
              </v-tab>
            </v-tabs>
            <v-form v-if="tab == 3" ref="form" lazy-validation v-model="valid">
              <v-text-field
                color="secondary"
                v-model="form.email"
                :disabled="loading"
                label="Email"
                :rules="rule.nameRules"
                prepend-icon="mdi-account"
              />

              <v-text-field
                class="mb-8"
                :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                color="secondary"
                v-model="form.password"
                :disabled="loading"
                :rules="rule.passwordRules"
                label="Mật khẩu"
                @keyup.enter="login"
                prepend-icon="mdi-lock-outline"
                :type="show ? 'text' : 'password'"
              />
              <pages-btn
                large
                color
                depressed
                @click="login"
                :loading="loading"
                class="v-btn--text success--text"
                >Đăng nhập
              </pages-btn>
            </v-form>
            <template v-else>
              <pages-btn
                large
                color="green"
                depressed
                @click="getMicrosoftLoginPage"
                :loading="loading"
                class=" mt-3"
                >Đăng nhập với tài khoản Microsoft
              </pages-btn>
            </template>

            <div class="mt-4" style="font-weight: bold" v-if="examination">
              {{ message }}
            </div>
            <p
              v-if="
                $store.state.user.user &&
                  examination &&
                  examination.checkInStudents &&
                  message &&
                  new Date(examination.startTime) < new Date()
              "
            >
              Bạn đang đăng nhập với email:
              <span style="font-weight: bold">{{
                $store.state.user.user.email
              }}</span
              >, nếu sai
              <a style="font-weight:bold" href="/msal/student"
                >hãy bấm vào link này</a
              >
              để vào lại email của mình.
            </p>
            <div class="mt-4" v-if="!$route.query.admin && examination">
              <div>
                Kíp thi <b>"{{ examination.name }}"</b> bắt đầu lúc
              </div>
              <div class="mt-1">{{ examStartTime }}</div>
            </div>
            <div class="mt-4" v-if="!$route.query.admin && !examination">
              <div>Hiện tại không có kíp thi nào.</div>
            </div>
            <div
              class="mt-4 red--text"
              v-if="
                !$route.query.admin && examination && !examination.isTesting && !examination.ownerId
              "
            >
              Bạn sinh viên nào không tham gia kíp thi này thì không được đăng
              nhập. Nếu đăng nhập, được hiểu là đang thực hiện hành vi phá hại
              và sẽ bị xử lý theo quy chế của nhà trường!!!
            </div>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { getNearestExamination } from "@/api/examination";
import dayjs from "dayjs";

export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("@/layouts/pages/components/Btn")
  },
  watch: {
    tab(val) {
      if (val == 3) {
        if (this.$store.state.user.auth)
          window.location.href = "/admin/original-exams";
      }
    }
  },
  data: () => ({
    valid: true,
    show: false,
    tab: 0,
    loading: false,
    form: {
      email: "",
      password: ""
    },
    examination: undefined,
    message: undefined,
    rule: {
      nameRules: [v => !!v || "Bạn chưa nhập tên đăng nhập"],
      passwordRules: [v => !!v || "Bạn chưa nhập password"]
    },
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ]
  }),
  computed: {
    examStartTime() {
      if (this.examination) {
        return dayjs(this.examination.startTime).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return undefined;
      }
    },
    shareId() {
      return sessionStorage.getItem('share')
    }
  },
  async mounted() {
    const result = await getNearestExamination({share: this.shareId});
    this.examination = result.data;
    const msg = this.$store.state.app.errorMessage;
    if (msg) {
      this.message = msg;
      this.$store.commit("app/SET_ERROR_MESSAGE", undefined);
    }
  },
  methods: {
    login() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;

      this.$store
        .dispatch("user/login", this.form)
        .then(homeUrl => {
          this.loading = false;
          // window.location.reload();
          this.$router.replace("/admin/examinations");
        })
        .catch(error => {
          this.loading = false;
          if (error.response.data.error === "Invalid Credentials") {
            this.$snackbar("Sai tài khoản hoặc mật khẩu", "error");
            this.loading = false;
          }
        });
    },
    getMicrosoftLoginPage() {
      if (this.$store.state.user.auth) {
        if (!this.$route.query.admin) {
          window.location.href = "/student";
        } else {
          if (this.tab === 0) window.location.href = "/admin/exam-monitoring";
          else if (this.tab === 1)
            window.location.href = "/admin/supervisors/monitoring";
          else if (this.tab === 2) window.location.href = "/canborade";
        }
      } else {
        const host =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3333/msal/"
            : "/msal/";
        const param = this.$route.query.admin ? "admin" : "student";
        window.location.href = host + param;
      }
    }
  }
};
</script>
